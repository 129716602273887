<template>
  <v-tabs centered v-model="tab" ref="tabs">
    <template v-for="(rootItem, i) in filteredItems">
      <v-tab :key="i" v-if="hasAccess(rootItem) && rootItem.children" @click="showMenu[i] = !showMenu[i]">
        <v-menu offset-y v-model="showMenu[i]">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" @click="showMenu[i] = !showMenu[i]"
              >{{ rootItem.text }}<v-icon>mdi-menu-down</v-icon></span
            >
          </template>
          <v-list dense flat>
            <v-list-item-group active-class="">
              <template v-for="(item, j) in rootItem.children">
                <v-menu offset-x :key="j">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      :key="j"
                      link
                      v-if="hasAccess(item)"
                      :to="!item.children ? item.to : null"
                      @click="
                        chooseTab(i);
                        hideAllMenus(!item.children);
                      "
                    >
                      <v-list-item-content v-bind="attrs" v-on="on" @click="hideAllMenus(!item.children)">
                        <v-list-item-title
                          dense
                          @click="
                            chooseTab(i);
                            hideAllMenus(!item.children);
                          "
                        >
                          <span>{{ item.text }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon v-if="item.children" v-bind="attrs" v-on="on">
                        <v-icon>mdi-menu-right</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <v-list v-if="item.children" dense flat>
                    <v-list-item-group active-class="">
                      <v-list-item
                        @click="hideAllMenus(true)"
                        hidelink
                        v-for="innerItem in item.children"
                        :key="innerItem.text"
                        :to="innerItem.to"
                      >
                        <v-list-item-content @click="hideAllMenus(true)">
                          <v-list-item-title dense @click="hideAllMenus(true)"
                            ><span>{{ innerItem.text }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-tab>
      <v-tab v-else-if="hasAccess(rootItem)" :key="i" :to="rootItem.to" @click="showMenu[i] = !showMenu[i]">
        <span>{{ rootItem.text }}</span>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopMenu",
  data: () => ({
    loading: true,
    tab: 1,
    currentTab: 1,
    filteredItems: [],
    customReports: [],
    showMenu: []
  }),
  watch: {
    selectedProgram() {
      this.getData();
    },
    impersonationParticipant() {
      this.fetchCustomReports();
    },
    filteredItems: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.tabs.onResize();
          setTimeout(() => {
            this.$refs.tabs.onResize();
          }, 250);
        });
      }
    }
  },
  mounted() {
    this.filteredItems = this.items.filter(elem => this.hasAccess(elem));
    this.getData();
  },
  methods: {
    hideAllMenus(value) {
      if (value) {
        this.showMenu = this.showMenu.map(() => false);
        this.$forceUpdate();
      }
    },

    getData() {
      this.fetchCustomReports();
    },

    fetchCustomReports() {
      this.$api.get("/api/customReports/myReports").then(({ data }) => {
        let reports = [];
        data
          .filter(elem => elem.showInTopMenu)
          .sort((a, b) => a.rank - b.rank)
          .forEach(report => {
            if (!reports[report.category.name]) {
              reports[report.category.name] = [];
            }
            reports[report.category.name].push({
              text: report.name,
              to: {
                name: "cprCustomReport",
                params: { id: report.id }
              }
            });
          });

        this.customReports = [];
        Object.keys(reports).forEach(key => {
          this.customReports.push({
            text: key,
            children: reports[key].sort((a, b) => a.rank - b.rank)
          });
        });
        this.loading = false;
        this.filterItems();
      });
    },
    hasAccess(menuItem) {
      // Check if participant is requester (Promotion Invitation flow)
      if (menuItem && menuItem.requester) {
        if (!this.isSelectedParticipantARequester) {
          return false;
        }
      }
      // Check if participant is approval (Promotion Invitation flow)
      if (menuItem && menuItem.approval) {
        if (!this.isSelectedParticipantAnApprover) {
          return false;
        }
      }
      // Check programs and return false if not part of program
      if (menuItem && menuItem.programs && menuItem.programs.length > 0) {
        if (
          this.selectedProgram &&
          this.selectedProgram.programKey &&
          !menuItem.programs.includes(this.selectedProgram.programKey)
        ) {
          return false;
        }
      }

      if (menuItem && menuItem.impersonation) {
        if (!this.isImpersonating) {
          return false;
        }
      }
      if (menuItem && menuItem.participantTypes && menuItem.participantTypes.length > 0) {
        if (
          this.selectedParticipant &&
          this.selectedParticipant.participantType &&
          menuItem.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    chooseTab(i) {
      this.tab = i;
      if (this.$refs && this.$refs.tabs) {
        this.$refs.tabs.onResize();
      }
    },
    filterItems() {
      this.filteredItems = this.items.filter(elem => this.hasAccess(elem));
    }
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedParticipant", "impersonationParticipant", "selectedProgram"]),
    isImpersonating() {
      return this.impersonationParticipant && this.impersonationParticipant.id;
    },
    items() {
      return [
        {
          text: this.$i18n.translate("Home"),
          to: { name: "dashboard" }
        },
        {
          text: this.$i18n.translate("Profile"),
          to: { name: "profile" },
          programs: [process.env.VUE_APP_PROGRAM_EVE_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY]
        },
        {
          participantTypes: [
            "500",
            "520",
            "530",
            "600",
            "620",
            "700",
            "720",
            "730",
            "800",
            "820",
            "830",
            "900",
            "930",
            "1030"
          ],
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY],
          text: this.$i18n.translate("Claims"),
          to: { name: "cprMyClaims" }
        },
        {
          text: this.$i18n.translate("Bulk Claiming"),
          children: [
            {
              text: this.$i18n.translate("Claim via File Feed"),
              to: { name: "cprClaimsFileFeed" }
            }
          ],
          impersonation: true,
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY],
          participantTypes: ["100", "500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"]
        },
        {
          participantTypes: [
            "500",
            "510",
            "520",
            "530",
            "600",
            "610",
            "620",
            "700",
            "710",
            "720",
            "730",
            "800",
            "810",
            "820",
            "830",
            "900",
            "910",
            "930",
            "1030"
          ],
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY],
          text: this.$i18n.translate("Earnings"),
          to: { name: "cprMyEarnings" }
        },
        {
          participantTypes: ["100", "500", "600", "700", "800"],
          programs: [process.env.VUE_APP_PROGRAM_CCPR_KEY],
          text: this.$i18n.translate("Company Earnings"),
          to: { name: "ccprMyCompanyEarnings" }
        },
        {
          participantTypes: ["300"],
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY],
          text: this.$i18n.translate("My Account Managers"),
          to: { name: "cprMyAccountManagers" }
        },
        {
          participantTypes: ["400"],
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY],
          text: this.$i18n.translate("My Accounts"),
          to: { name: "cprMyAccounts" }
        },
        {
          text: "My Company",
          participantTypes: ["100", "500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"],
          programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY],
          children: [
            {
              participantTypes: ["500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"],
              text: this.$i18n.translate("My Company Management"),
              to: { name: "cprMyCompanyManagement" }
            },
            {
              participantTypes: ["500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"],
              text: this.$i18n.translate("My Company Claims"),
              to: { name: "cprMyCompanyClaims" }
            },
            {
              participantTypes: ["100", "500", "510", "600", "610", "700", "710", "800", "810", "900", "910"],
              text: this.$i18n.translate("Registration Management"),
              to: { name: "cprRegistrationManagement" },
              programs: [process.env.VUE_APP_PROGRAM_CCPR_KEY]
            }
          ]
        },
        {
          participantTypes: [
            "100",
            "200",
            "300",
            "400",
            "500",
            "510",
            "520",
            "530",
            "600",
            "610",
            "620",
            "700",
            "710",
            "720",
            "730",
            "800",
            "810",
            "820",
            "830",
            "900",
            "910",
            "930",
            "1030"
          ],
          text: this.$i18n.translate("Reports"),
          programs: [
            process.env.VUE_APP_PROGRAM_CPR_KEY,
            process.env.VUE_APP_PROGRAM_CCPR_KEY,
            process.env.VUE_APP_PROGRAM_CEUR_KEY,
            process.env.VUE_APP_PROGRAM_EUR_KEY
          ],
          children: [
            {
              text: this.$i18n.translate("Claim Reporting"),
              to: { name: "allClaimTypesReporting" }
            },
            {
              participantTypes: ["100", "200", "300", "400"],
              text: this.$i18n.translate("Enrollment Reporting"),
              to: { name: "enrollmentReporting" }
            },

            {
              text: this.$i18n.translate("Report Downloads"),
              to: { name: "reportDownloads" }
            }
          ].concat(this.customReports)
        },
        {
          participantTypes: ["100"],
          text: this.$i18n.translate("Reports"),
          programs: [process.env.VUE_APP_PROGRAM_EVE_KEY],
          children: [
            {
              text: this.$i18n.translate("Enrollment Reporting"),
              to: { name: "enrollmentReporting" }
            },

            {
              text: this.$i18n.translate("Report Downloads"),
              to: { name: "reportDownloads" }
            }
          ].concat(this.customReports)
        },
        {
          text: "Admin",
          participantTypes: ["100", "400", "1000", "1100"],
          children: [
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Promotion Library"),
              to: { name: "salesnewsLibrary" },
              programs: [
                process.env.VUE_APP_PROGRAM_CPR_KEY,
                process.env.VUE_APP_PROGRAM_CCPR_KEY,
                process.env.VUE_APP_PROGRAM_CEUR_KEY,
                process.env.VUE_APP_PROGRAM_EUR_KEY
              ]
            },
            {
              participantTypes: ["100", "400"],
              text: this.$i18n.translate("Registration Management"),
              to: { name: "cprRegistrationManagement" },
              programs: [process.env.VUE_APP_PROGRAM_CPR_KEY]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Company Management"),
              to: { name: "cprCompanyManagement" },
              programs: [process.env.VUE_APP_PROGRAM_CPR_KEY, process.env.VUE_APP_PROGRAM_CCPR_KEY]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Participant Management"),
              to: { name: "cprParticipantManagement" },
              programs: [
                process.env.VUE_APP_PROGRAM_CPR_KEY,
                process.env.VUE_APP_PROGRAM_CCPR_KEY,
                process.env.VUE_APP_PROGRAM_EVE_KEY,
                process.env.VUE_APP_PROGRAM_CEUR_KEY
              ]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Claim Audit Management"),
              to: { name: "cprClaimAuditManagement" },
              programs: [
                process.env.VUE_APP_PROGRAM_CPR_KEY,
                process.env.VUE_APP_PROGRAM_CCPR_KEY,
                process.env.VUE_APP_PROGRAM_CEUR_KEY,
                process.env.VUE_APP_PROGRAM_EUR_KEY
              ]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Claim Payment Management"),
              to: { name: "cprClaimPaymentManagement" },
              programs: [
                process.env.VUE_APP_PROGRAM_CPR_KEY,
                process.env.VUE_APP_PROGRAM_CCPR_KEY,
                process.env.VUE_APP_PROGRAM_CEUR_KEY,
                process.env.VUE_APP_PROGRAM_EUR_KEY
              ]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Closed Claims"),
              to: { name: "cprClosedClaims" },
              programs: [
                process.env.VUE_APP_PROGRAM_CPR_KEY,
                process.env.VUE_APP_PROGRAM_CCPR_KEY,
                process.env.VUE_APP_PROGRAM_CEUR_KEY,
                process.env.VUE_APP_PROGRAM_EUR_KEY
              ]
            },
            {
              participantTypes: ["100"],
              text: this.$i18n.translate("Survey Management"),
              to: { name: "cprSurveys" }
            },
            {
              participantTypes: ["100", "1000", "1100"],
              text: this.$i18n.translate("Promotion Invitations"),
              to: { name: "promotionInvitations" },
              programs: [process.env.VUE_APP_PROGRAM_CEUR_KEY]
            }
          ]
        }
      ];
    }
  }
};
</script>
